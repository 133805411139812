import React from 'react';
import './packageDesigner.css';
import { Row, Col, Button, CustomInput } from 'reactstrap';
import Img from 'gatsby-image';
import StickyBox from 'react-sticky-box';
import Pluralize from 'react-pluralize';
import { StaticQuery, graphql } from 'gatsby';
import {
  // faWifi,
  faTrashAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
// library.add(fab, faCheckSquare, faCoffee)
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BookingInputBox from 'components/bookingInputBox';
import {
  DateRangePicker,
  SingleDatePicker
} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import axios from 'axios';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import {
  BrowserView,
  MobileView
} from "react-device-detect";
import Spinner from 'react-svg-spinner';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import Analytics from 'analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'
import GenericTitleTextCTA from 'components/generic/TitleTextCTA'
// import fbTrack from './fb-track'

// addToCartPixelEvent = (content_name, content_category, content_type, value, currency) => fbTrack('track', 'AddToCart', {
//   content_name: content_name,
//   content_category: content_category,
//   // content_ids: ['1234'],
//   content_type: content_type,
//   value: value,
//   currency: currency
// })

// initateCheckoutPixelEvent = () => fbTrack('track', 'initateCheckout')

const API = {
  processPayment:
    'https://gracegalaxyinstamojopaymentprocessor.negati-ve.now.sh/api/processPayment',
  // 'http://localhost:3000/api/processPayment',
  postPayment:
    'https://gracegalaxyinstamojopaymentprocessor.negati-ve.now.sh/api/postPayment',
  // 'http://localhost:3000/api/postPayment',
  djuboAvailability:
    'https://djubo-client.negati-ve.now.sh/api/getAvailability',
};
var querystring = require('querystring');

/* Initialize analytics */
const analytics = Analytics({
  app: 'HGGBook',
  version: 100,
  plugins: [
    googleAnalyticsPlugin({
      trackingId: 'UA-60584820-9',
    })
  ]
})

class PackageDesigner extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props.checkout,'asd')
    this.state = {
      swipeableBottomSheetOpen: false,
      toastVisible: false,
      loading: false,
      categoryMap: {
        19741: 'compact',
        19742: 'deluxe',
        19744: 'superior',
        19745: 'royal',
        19752: 'twin',
        19743: 'triple',
      },
      available: {
        compact: 0,
        deluxe: 0,
        superior: 0,
        royal: 0,
        twin: 0,
        triple: 0,
      },
      rate: {
        compact: 4000,
        deluxe: 4599,
        superior: 4999,
        royal: 5999,
        twin: 5899,
        triple: 6499,
        breakfast: 300,
        pickup: 900,
        drop: 900,
      },
      cart: {
        rooms: {
          compact: 0,
          deluxe: 0,
          superior: 0,
          royal: 0,
          twin: 0,
          triple: 0,
        },
        roomsByCategory: [],
        addons: {
          breakfast: 1,
          pickup: 0,
          drop: 0,
        },
        totalRooms: 0,
        totalCost: 0,
      },
      filterDateFocusedInput: null,
      filterDateStart: this.props.checkin
        ? moment(this.props.checkin)
        : moment(new Date()),
      isFilterDateEndUpdated: false,
      isFilterDateStartUpdated: false,
      filterDateEnd: this.props.checkout
        ? moment(this.props.checkout)
        : moment(new Date()).add(1, 'days'),
    };
  }

  componentDidMount = () => {
    // console.log(this.state)
    this.updateAvailabilityByDates(
      this.state.filterDateStart,
      this.state.filterDateEnd
    );
    /* Track a page view */
    analytics.page()
  };

  updateAvailabilityByDates = (startDate, endDate) => {
    /* Track event */
    analytics.track('userUpdatedAvailabilityDates', {
      startDate: startDate,
      endDate: endDate
    })
    this.setState({ loading: true });
    var newAvailable = {
      compact: 0,
      deluxe: 0,
      superior: 0,
      royal: 0,
      twin: 0,
      triple: 0,
    };
    var startDateString = moment(new Date(startDate)).format('YYYY-MM-DD');
    var endDateString = moment(new Date(endDate)).format('YYYY-MM-DD');
    // console.log(startDateString);
    var url =
      API.djuboAvailability +
      '?checkin=' +
      startDateString +
      '&checkout=' +
      endDateString;
    axios
      .get(url)
      .then(response => {
        // console.log(response)
        Object.keys(response.data).map((key, index) => {
          if (this.state.categoryMap[response.data[key].category]) {
            // category exists and we are aware of this category
            // update available inventory
            newAvailable[this.state.categoryMap[response.data[key].category]] =
              newAvailable[
              this.state.categoryMap[response.data[key].category]
              ] + 1;
          }
        });
        // console.log(available)
        this.setState({ available: newAvailable, loading: false });

        return response;
      })
      .catch(e => {
        console.log('error: get availability failed | ' + e);
        return {};
      });
  };

  dateFilterUpdated = (startDate, endDate) => {
    // console.log(startDate,endDate)
    var isFilterDateStartUpdated = false;
    var isFilterDateEndUpdated = false;
    // clear the cart
    var defaultCart = {
      rooms: {
        compact: 0,
        deluxe: 0,
        superior: 0,
        royal: 0,
        twin: 0,
        triple: 0,
      },
      addons: {
        breakfast: 1,
        pickup: 0,
        drop: 0,
      },
      roomsByCategory: [],
      totalRooms: 0,
      totalCost: 0,
    };
    if (startDate && startDate != this.state.filterDateStart) {
      isFilterDateStartUpdated = true;
      console.log(startDate, 'start updated');
      this.setState({
        cart: defaultCart,
        filterDateStart: startDate,
        filterDateEnd: null,
        isFilterDateStartUpdated: isFilterDateStartUpdated,
      });
    }

    if (endDate && endDate != this.state.filterDateEnd) {
      isFilterDateEndUpdated = true;
      console.log(endDate, 'endate updated');
      this.setState({
        cart: defaultCart,
        filterDateEnd: endDate,
        isFilterDateEndUpdated: true,
      });
    }

    // update availability
    if (isFilterDateEndUpdated) {
      console.log('updating');
      this.updateAvailabilityByDates(startDate, endDate);
      this.setState({
        isFilterDateStartUpdated: false,
        isFilterDateEndUpdated: false,
      });
    }
  };

  toggle = () => {
    this.setState({});
  };

  toggleBreakfast = e => {
    console.log('toggling breakfast');
    var newObj = { ...this.state.cart };
    newObj.addons.breakfast = !newObj.addons.breakfast;
    newObj.totalCost = this.calculateTotalCost();
    this.setState({ cart: newObj });
  };

  toggleAirportPickup = e => {
    console.log('toggling airport pickup');
    var newObj = { ...this.state.cart };
    newObj.addons.pickup = !newObj.addons.pickup;
    newObj.totalCost = this.calculateTotalCost();
    this.setState({ cart: newObj });
  };

  toggleAirportDrop = e => {
    console.log('toggling airport drop');
    var newObj = { ...this.state.cart };
    newObj.addons.drop = !newObj.addons.drop;
    newObj.totalCost = this.calculateTotalCost();
    this.setState({ cart: newObj });
  };

  addToCart = room => {
    /* Track event */
    analytics.track('userAddedRoomToCart', {
      room: room
    })
    // track pixel event
    // addToCartPixelEvent(room, 'room', 'room', this.state.rate[room], 'INR')
    Object.entries(this.state.available).map((k, i) => {
      if (k[0] == room && this.state.available[room] > 0) {
        var newCartObj = { ...this.state.cart };
        var newAvailableObj = { ...this.state.available };
        newCartObj.rooms[room] = newCartObj.rooms[room] + 1;
        newCartObj.totalRooms = newCartObj.totalRooms + 1;
        // newCartObj.totalCost = this.state.cart.totalCost + this.state.rate[room]
        newCartObj.totalCost = this.calculateTotalCost();
        newAvailableObj[room] = newAvailableObj[room] - 1;
        // console.log(newAvailableObj)

        //////////////////////////////////////////////////////////////////////////////////
        // get category Id and create array of rooms by categoryId to be sent over wire //
        /////////////////////////////////////////////////////////////////////////////////
        // swaping the key value for ease of use
        if (!newCartObj.roomsByCategory) newCartObj.roomsByCategory = [];
        var categoryMap = Object.assign(
          {},
          ...Object.entries(this.state.categoryMap).map(([a, b]) => ({
            [b]: a,
          }))
        );
        // push the new category Id to roomsByCategory Array
        newCartObj.roomsByCategory.push(categoryMap[room]);
        // console.log(newCartObj)
        this.setState({
          cart: newCartObj,
          available: newAvailableObj,
        });
        // this.showToast()
        this.toggleBottomSheet()
      }
    });
  };

  calculateTotalCost = () => {
    var totalCost = 0;
    var totalRooms = 0;
    Object.keys(this.state.cart.rooms).map((k, i) => {
      if (this.state.cart.rooms[k]) {
        totalCost = totalCost + this.state.cart.rooms[k] * this.state.rate[k];
        totalRooms = totalRooms + this.state.cart.rooms[k];
      }
    });

    // number of stay nights
    const roomNights = this.state.filterDateEnd.diff(
      this.state.filterDateStart,
      'days'
    );
    // multiply by the number of nights
    totalCost = totalCost * roomNights;
    // console.log(this.state.filterDateEnd.diff(this.state.filterDateStart,'days'))
    console.log(
      this.state.filterDateStart.format('YYYY-MM-DD'),
      this.state.filterDateEnd.format('YYYY-MM-DD')
    );
    console.log(
      this.state.filterDateEnd.diff(this.state.filterDateStart, 'days')
    );
    // add breakfast if enabled
    if (this.state.cart.addons.breakfast)
      totalCost =
        totalCost + this.state.rate.breakfast * totalRooms * roomNights;

    // add pickup if enabled
    if (this.state.cart.addons.pickup)
      totalCost = totalCost + this.state.rate.pickup;
    // add drop if enabled
    if (this.state.cart.addons.drop)
      totalCost = totalCost + this.state.rate.drop;

    return totalCost;
  };

  removeFromCart = room => {
    /* Track event */
    analytics.track('userRemovedRoomFromCart', {
      room: room
    })
    Object.entries(this.state.available).map((k, i) => {
      if (k[0] == room) {
        var newCartObj = { ...this.state.cart };
        newCartObj.rooms[room] = newCartObj.rooms[room] - 1;
        newCartObj.totalRooms = newCartObj.totalRooms - 1;
        var newAvailableObj = { ...this.state.available };
        // console.log(newAvailableObj)
        newAvailableObj[room] = newAvailableObj[room] + 1;
        //////////////////////////////////////////////////////////////////////////////////
        // get category Id and create array of rooms by categoryId to be sent over wire //
        /////////////////////////////////////////////////////////////////////////////////
        // swaping the key value for ease of use
        var categoryMap = Object.assign(
          {},
          ...Object.entries(this.state.categoryMap).map(([a, b]) => ({
            [b]: a,
          }))
        );
        // pop specific category Id from roomsByCategory Array
        // newCartObj.roomsByCategory.push(categoryMap[room])
        var index = newCartObj.roomsByCategory.indexOf(categoryMap[room]);
        if (index > -1) {
          newCartObj.roomsByCategory.splice(index, 1);
        }
        newCartObj.totalCost = this.calculateTotalCost();
        // console.log(newCartObj)
        this.setState({
          cart: newCartObj,
          available: newAvailableObj,
        });
      }
    });
  };

  generateSummary = () => {
    Object.keys(this.state.cart.rooms).map((k, i) => {
      return (
        <React.Fragment key={i}>
          <p>asd</p>
        </React.Fragment>
      );
    });
    // Object.entries(this.state.cart.rooms).map((k,i)=>{
    //   console.log(i,k)
    //   return <React.Fragment key={i}>
    //     <p>asd</p>
    //     </React.Fragment>
    // })
  };

  toggleBottomSheet = () => {
    analytics.track('userToggledBottomSwipeableSheet', {
      state: !this.state.swipeableBottomSheetOpen
    })
    this.setState({ swipeableBottomSheetOpen: !this.state.swipeableBottomSheetOpen })
  };

  incrementAdultCount = () => { };

  incrementChildCount = () => { };

  decrementRoomCount = () => { };

  decrementAdultCount = () => { };

  decrementChildCount = () => { };

  getInstaMojoRedirectUrl = () => {
    analytics.track('userClickedBookNow', {
      PotentialTxn: this.state.cart.totalCost
    })
    // pixel tracker
    // initateCheckoutPixelEvent()

    // console.log('payment initiated');
    var plan = 2;
    if (this.state.cart.addons.breakfast) {
      plan = 2;
    } else {
      plan = 1;
    }
    var checkin = moment(this.state.filterDateStart).format('YYYY-MM-DD');
    var checkout = moment(this.state.filterDateEnd).format('YYYY-MM-DD');

    var purpose = 'Rooms @ Hotel Grace Galaxy';

    var thankYouRedirectUrl =
      'https://hotelgracegalaxy.com/thankyou?rooms=[' + this.state.cart.roomsByCategory + ']&checkin=' + checkin + '&checkout=' + checkout + '&plan=' + plan + '&pickup=' + this.state.cart.addons.pickup + '&drop=' + this.state.cart.addons.drop;
    var redirectUrl = API.postPayment + '?amount=' + this.state.cart.totalCost + '&purpose=' + purpose + '&checkin=' + checkin + '&checkout=' + checkout + '&plan=' + plan + '&pickup=' + this.state.cart.addons.pickup + '&drop=' + this.state.cart.addons.drop + '&rooms=[' + this.state.cart.roomsByCategory + ']&redirect_url=' + thankYouRedirectUrl;
    redirectUrl = encodeURI(redirectUrl);
    // console.log(redirectUrl)

    // return 0
    var body = {
      rooms: this.state.cart.roomsByCategory,
      amount: this.state.cart.totalCost,
      purpose: purpose,
      checkin: moment(this.state.filterDateStart).format('YYYY-MM-DD'),
      checkout: moment(this.state.filterDateEnd).format('YYYY-MM-DD'),
      plan: plan,
      pickup: this.state.cart.addons.pickup,
      drop: this.state.cart.addons.drop,
      redirect_url: redirectUrl,
    };
    axios
      .post(API.processPayment, querystring.stringify(body))
      .then(response => {
        console.log(response);
        // redirect URl
        if (typeof window !== 'undefined') {
          // console.log(response.data.payment_link)
          // console.log(JSON.parse(response.data))
          window.location.href = response.data.payment_link;
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  showToast = () => {
    this.setState({ toastVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ toastVisible: false })
      }, 5000)
    });
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            royal: file(
              relativePath: {
                eq: "images/gallery/royal-hotel-room-bandra-mumbai.jpg"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            superior: file(
              relativePath: {
                eq: "images/gallery/superior.jpg"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            budget: file(
              relativePath: {
                eq: "images/gallery/compact.jpg"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            deluxe: file(
              relativePath: {
                eq: "images/gallery/hotel-room-for-couples-in-bandra.JPG"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            twin: file(
              relativePath: {
                eq: "images/gallery/twin.jpg"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
             triple: file(
              relativePath: {
                eq: "images/gallery/triple.jpg"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => (
          <Row>
            <div style={{ position: "fixed", top: "40px", zIndex: "1", float: "right", width: "100%" }}>
              <Toast style={{ position: "relative", margin: "0 auto" }} isOpen={this.state.toastVisible}>
                <ToastHeader icon="warning">
                  Room added to cart
                </ToastHeader>
                <ToastBody>
                  Please check the drawer at the bottom of your screen
                </ToastBody>
              </Toast>
            </div>

            <Col
              lg={{ size: 8 }}
              xs={{ size: 12 }}
              style={{ marginTop: '20px' }}
            >
              <h1 style={{ fontFamily: 'AllerLight', textAlign: 'center' }}>
                Hotel rooms at best rates online in Mumbai. Guaranteed.
              </h1>
              <p>*Please note: We are now open for all guests in compliance with WHO guidelines and safety standards.</p>
              <br /> <p>All Bookings are refundable only if cancelled 24 hours before check in. For more details, Please call <a href="tel:+919538740296">+91 9538740296</a></p>
              <hr />

              {/* Display box */}
              <Row
                style={{
                  margin: '0px auto',
                  padding: '2rem 1rem',
                  boxShadow: '0 .225rem .75rem 0 rgba(0,0,0,.1)',
                  width: '100%',
                }}
              >
                <Col lg={{ size: 4 }} md={{ size: 4 }} xs={{ size: 12 }}>
                  <Img
                    style={{ borderRadius: '4px' }}
                    fluid={data.budget.childImageSharp.fluid}
                  ></Img>
                </Col>
                <Col lg={{ size: 8 }} xs={{ size: 12 }}>
                  <h2 style={{ fontFamily: 'AllerLight' }}>Classic Compact - Rs.{this.state.rate.compact}/Night</h2>{' '}
                  <span style={{ color: 'red' }}>
                    {this.state.available['compact'] ? (
                      <p>
                        {' '}
                        Only {this.state.available['compact']} rooms available
                      </p>
                    ) : (
                      <p>last room just sold out!</p>
                    )}
                  </span>
                  <p>Pocket friendly tiny rooms with AC, Free Wifi, In-room coffee maker and more</p>
                  <p>
                    Max allowed guests:
                    <FontAwesomeIcon
                      icon={faUser}
                      // onClick={()=>this.addToCart(room)}
                      style={{
                        fontSize: '16px',
                        color: '#EF8921',
                        margin: '1px',
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faUser}
                      // onClick={()=>this.addToCart(room)}
                      style={{
                        fontSize: '16px',
                        color: '#EF8921',
                        margin: '1px',
                      }}
                    />
                  </p>
                  <Button
                    style={{ marginRight: '10px', float: 'right' }}
                    onClick={() => this.addToCart('compact')}
                    className="roomGuestSelectorButtonStyle"
                  >
                    {this.state.loading ? <Spinner /> : ''}
                    {this.state.available['compact']
                      ? 'Add to your reservation'
                      : 'Sold out'}
                  </Button>
                </Col>
              </Row>
              {/* End of Display box */}

              {/* Display box */}
              <Row
                style={{
                  margin: '50px auto',
                  padding: '2rem 1rem',
                  boxShadow: '0 .225rem .75rem 0 rgba(0,0,0,.1)',
                  width: '100%',
                }}
              >
                <Col lg={{ size: 4 }} md={{ size: 4 }} xs={{ size: 12 }}>
                  <Img
                    style={{ borderRadius: '4px' }}
                    fluid={data.deluxe.childImageSharp.fluid}
                  ></Img>
                </Col>
                <Col lg={{ size: 8 }} xs={{ size: 12 }}>
                  <h2 style={{ fontFamily: 'AllerLight' }}>Deluxe - Rs.{this.state.rate.deluxe}/Night</h2>{' '}
                  <span style={{ color: 'red' }}>
                    {this.state.available['deluxe'] ? (
                      <p>
                        {' '}
                        Only {this.state.available['deluxe']} rooms available
                      </p>
                    ) : (
                      <p>last room just sold out!</p>
                    )}
                  </span>
                  <p>Spacious rooms for laid back travelers </p>
                  <p>
                    Max allowed guests:
                    <FontAwesomeIcon
                      icon={faUser}
                      // onClick={()=>this.addToCart(room)}
                      style={{
                        fontSize: '16px',
                        color: '#EF8921',
                        margin: '1px',
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faUser}
                      // onClick={()=>this.addToCart(room)}
                      style={{
                        fontSize: '16px',
                        color: '#EF8921',
                        margin: '1px',
                      }}
                    />
                  </p>
                  <Button
                    style={{ marginRight: '10px', float: 'right' }}
                    onClick={() => this.addToCart('deluxe')}
                    className="roomGuestSelectorButtonStyle"
                  >
                    {this.state.loading ? <Spinner /> : ''}
                    {this.state.available['deluxe']
                      ? 'Add to your reservation'
                      : 'Sold out'}
                  </Button>
                </Col>
              </Row>
              {/* End of Display box */}

              {/* Display box */}
              <Row
                style={{
                  margin: '50px auto',
                  padding: '2rem 1rem',
                  boxShadow: '0 .225rem .75rem 0 rgba(0,0,0,.1)',
                  width: '100%',
                }}
              >
                <Col lg={{ size: 4 }} md={{ size: 4 }} xs={{ size: 12 }}>
                  <Img
                    style={{ borderRadius: '4px' }}
                    fluid={data.superior.childImageSharp.fluid}
                  ></Img>
                </Col>
                <Col lg={{ size: 8 }} xs={{ size: 12 }}>
                  <h2 style={{ fontFamily: 'AllerLight' }}>Superior - Rs.{this.state.rate.superior}/Night</h2>{' '}
                  <span style={{ color: 'red' }}>
                    {this.state.available['superior'] ? (
                      <p>
                        {' '}
                        Only {this.state.available['superior']} rooms available
                      </p>
                    ) : (
                      <p>last room just sold out!</p>
                    )}
                  </span>
                  <p>Superior spacious rooms with great view and amenities</p>
                  <p>
                    Max allowed guests:
                    <FontAwesomeIcon
                      icon={faUser}
                      // onClick={()=>this.addToCart(room)}
                      style={{
                        fontSize: '16px',
                        color: '#EF8921',
                        margin: '1px',
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faUser}
                      // onClick={()=>this.addToCart(room)}
                      style={{
                        fontSize: '16px',
                        color: '#EF8921',
                        margin: '1px',
                      }}
                    />
                  </p>
                  <Button
                    style={{ marginRight: '10px', float: 'right' }}
                    onClick={() => this.addToCart('superior')}
                    className="roomGuestSelectorButtonStyle"
                  >
                    {this.state.loading ? <Spinner /> : ''}
                    {this.state.available['superior']
                      ? 'Add to your reservation'
                      : 'Sold out'}
                  </Button>
                </Col>
              </Row>
              {/* End of Display box */}

              {/* Display box */}
              <Row
                style={{
                  margin: '50px auto',
                  padding: '2rem 1rem',
                  boxShadow: '0 .225rem .75rem 0 rgba(0,0,0,.1)',
                  width: '100%',
                }}
              >
                <Col lg={{ size: 4 }} md={{ size: 4 }} xs={{ size: 12 }}>
                  <Img
                    style={{ borderRadius: '4px' }}
                    fluid={data.twin.childImageSharp.fluid}
                  ></Img>
                </Col>
                <Col lg={{ size: 8 }} xs={{ size: 12 }}>
                  <h2 style={{ fontFamily: 'AllerLight' }}>Superior Twin - Rs.{this.state.rate.twin}/Night</h2>{' '}
                  <span style={{ color: 'red' }}>
                    {this.state.available['twin'] ? (
                      <p>
                        {' '}
                        Only {this.state.available['twin']} rooms available
                      </p>
                    ) : (
                      <p>last room just sold out!</p>
                    )}
                  </span>
                  <p>
                    Spacious rooms with two separate beds for people who need
                    their own space!{' '}
                  </p>
                  <p>
                    Max allowed guests:
                    <FontAwesomeIcon
                      icon={faUser}
                      // onClick={()=>this.addToCart(room)}
                      style={{
                        fontSize: '16px',
                        color: '#EF8921',
                        margin: '1px',
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faUser}
                      // onClick={()=>this.addToCart(room)}
                      style={{
                        fontSize: '16px',
                        color: '#EF8921',
                        margin: '1px',
                      }}
                    />
                  </p>
                  <Button
                    style={{ marginRight: '10px', float: 'right' }}
                    onClick={() => this.addToCart('twin')}
                    className="roomGuestSelectorButtonStyle"
                  >
                    {this.state.loading ? <Spinner /> : ''}

                    {this.state.available['twin']
                      ? 'Add to your reservation'
                      : 'Sold out'}
                  </Button>
                </Col>
              </Row>
              {/* End of Display box */}

              {/* Display box */}
              <Row
                style={{
                  margin: '50px auto',
                  padding: '2rem 1rem',
                  boxShadow: '0 .225rem .75rem 0 rgba(0,0,0,.1)',
                  width: '100%',
                }}
              >
                <Col lg={{ size: 4 }} md={{ size: 4 }} xs={{ size: 12 }}>
                  <Img
                    style={{ borderRadius: '4px' }}
                    fluid={data.triple.childImageSharp.fluid}
                  ></Img>
                </Col>
                <Col lg={{ size: 8 }} xs={{ size: 12 }}>
                  <h2 style={{ fontFamily: 'AllerLight' }}>Triple - Rs.{this.state.rate.triple}/Night</h2>{' '}
                  <span style={{ color: 'red' }}>
                    {this.state.available['triple'] ? (
                      <p>
                        {' '}
                        Only {this.state.available['triple']} rooms available
                      </p>
                    ) : (
                      <p>last room just sold out!</p>
                    )}
                  </span>
                  <p>Built for family and friends </p>
                  <p>
                    Max allowed guests:
                    <FontAwesomeIcon
                      icon={faUser}
                      // onClick={()=>this.addToCart(room)}
                      style={{
                        fontSize: '16px',
                        color: '#EF8921',
                        margin: '1px',
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faUser}
                      // onClick={()=>this.addToCart(room)}
                      style={{
                        fontSize: '16px',
                        color: '#EF8921',
                        margin: '1px',
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faUser}
                      // onClick={()=>this.addToCart(room)}
                      style={{
                        fontSize: '16px',
                        color: '#EF8921',
                        margin: '1px',
                      }}
                    />
                  </p>
                  <Button
                    style={{ marginRight: '10px', float: 'right' }}
                    onClick={() => this.addToCart('triple')}
                    className="roomGuestSelectorButtonStyle"
                  >
                    {this.state.loading ? <Spinner /> : ''}

                    {this.state.available['triple']
                      ? 'Add to your reservation'
                      : 'Sold out'}
                  </Button>
                </Col>
              </Row>
              {/* End of Display box */}

              {/* Display box */}
              <Row
                style={{
                  margin: '50px auto',
                  padding: '2rem 1rem',
                  boxShadow: '0 .225rem .75rem 0 rgba(0,0,0,.1)',
                  width: '100%',
                }}
              >
                <Col lg={{ size: 4 }} md={{ size: 4 }} xs={{ size: 12 }}>
                  <Img
                    style={{ borderRadius: '4px' }}
                    fluid={data.royal.childImageSharp.fluid}
                  ></Img>
                </Col>
                <Col lg={{ size: 8 }} xs={{ size: 12 }}>
                  <h2 style={{ fontFamily: 'AllerLight' }}>Royal - Rs.{this.state.rate.royal}/Night</h2>{' '}
                  <span style={{ color: 'red' }}>
                    {this.state.available['royal'] ? (
                      <p>
                        {' '}
                        Only {this.state.available['royal']} rooms available
                      </p>
                    ) : (
                      <p>last room just sold out!</p>
                    )}
                  </span>
                  <p>Extraordinary rooms with everything luxury </p>
                  <p>
                    Max allowed guests:
                    <FontAwesomeIcon
                      icon={faUser}
                      // onClick={()=>this.addToCart(room)}
                      style={{
                        fontSize: '16px',
                        color: '#EF8921',
                        margin: '1px',
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faUser}
                      // onClick={()=>this.addToCart(room)}
                      style={{
                        fontSize: '16px',
                        color: '#EF8921',
                        margin: '1px',
                      }}
                    />
                  </p>
                  <Button
                    style={{ marginRight: '10px', float: 'right' }}
                    onClick={() => this.addToCart('royal')}
                    className="roomGuestSelectorButtonStyle"
                  >
                    {this.state.loading ? <Spinner /> : ''}
                    {this.state.available['royal']
                      ? 'Add to your reservation'
                      : 'Sold out'}
                  </Button>
                </Col>
              </Row>
              {/* <GenericTitleTextCTA
                title="Need help?"
                text={<span>Call us at <a href="tel:+919538740296">+91 9538740296</a> for assistance, bulk booking and other details</span>}
                // callToActionText="Know more"
                callToActionUrl="/about" /> */}
              {/* End of Display box */}
            </Col>
            <Col lg={{ size: 4 }}>
              <BrowserView style={{ height: "100%" }}>
                <StickyBox
                  offsetTop={20}
                  offsetBottom={20}
                  style={{
                    padding: '10px',
                    marginTop: '80px',
                    boxShadow: '0 .225rem .75rem 0 rgba(0,0,0,.1)',
                  }}
                >
                  <div>
                    <h4 style={{ textAlign: 'center', fontFamily: 'AllerLight' }}>
                      Reservation Summary
                    </h4>
                    <hr />
                    <DateRangePicker
                      horizontalMargin="12"
                      orientation="vertical"
                      startDateId="startDate"
                      startDatePlaceholderText="Check In "
                      endDatePlaceholderText="Check Out "
                      endDateId="endDate"
                      startDate={this.state.filterDateStart}
                      endDate={this.state.filterDateEnd}
                      onDatesChange={({ startDate, endDate }) => {
                        this.dateFilterUpdated(startDate, endDate);
                      }}
                      focusedInput={this.state.filterDateFocusedInput}
                      onFocusChange={focusedInput => {
                        document.activeElement.blur();
                        this.setState({ filterDateFocusedInput: focusedInput });
                      }}
                    />
                    <hr />
                    <h4>Rooms</h4>
                    {Object.entries(this.state.cart.rooms).map((k, i) => {
                      return (
                        <React.Fragment key={i}>
                          {k[1] > 0 && (
                            <React.Fragment>
                              <div>
                                <p
                                  style={{
                                    textTransform: 'capitalize',
                                    width: '140px',
                                    display: 'inline-block',
                                  }}
                                >
                                  {k[0]}
                                </p>
                                <span style={{}}> X {k[1]}</span>

                                <span style={{ float: 'right' }}>
                                  <Button
                                    onClick={() => this.removeFromCart(k[0])}
                                    style={{
                                      margin: 0,
                                      background: 'none',
                                      border: 'none',
                                      padding: 0,
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      // onClick={()=>this.addToCart(room)}
                                      style={{
                                        fontSize: '16px',
                                        color: '#EF8921',
                                        margin: '1px',
                                      }}
                                    />
                                  </Button>
                                </span>
                                <span style={{ float: 'right' }}>
                                  Rs. {this.state.rate[k[0]]}
                                </span>
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      );
                    })}
                    <hr />
                    <h4>Addons</h4>
                    <CustomInput
                      className="custom-switch"
                      type="checkbox"
                      id="breakfastToggleSwitch"
                      name="customSwitch"
                      onChange={e => this.toggleBreakfast()}
                      checked={this.state.cart.addons.breakfast ? true : false}
                      label="Breakfast"
                      style={{ display: 'inline' }}
                    >
                      <span style={{ float: 'right' }}>
                        Rs.
                        {this.state.cart.addons.breakfast
                          ? this.state.rate.breakfast * this.state.cart.totalRooms
                          : 0}
                      </span>
                    </CustomInput>
                    <CustomInput
                      type="switch"
                      id="airportPickupToggleSwitch"
                      name="pickupToggleSwitch"
                      onChange={e => this.toggleAirportPickup()}
                      checked={this.state.cart.addons.pickup ? true : false}
                      label="Airport Pickup"
                      style={{ display: 'inline' }}
                    >
                      <span style={{ float: 'right' }}>
                        Rs.{' '}
                        {this.state.cart.addons.pickup
                          ? this.state.rate.pickup
                          : 0}
                      </span>
                    </CustomInput>
                    <CustomInput
                      type="switch"
                      id="airportDropToggleSwitch"
                      name="dropToggleSwitch"
                      onChange={e => this.toggleAirportDrop()}
                      checked={this.state.cart.addons.drop ? true : false}
                      label="Airport Drop"
                      style={{ display: 'inline' }}
                    >
                      <span style={{ float: 'right' }}>
                        Rs.{' '}
                        {this.state.cart.addons.drop ? this.state.rate.drop : 0}
                      </span>
                    </CustomInput>
                    <hr />
                    <h4 style={{ display: 'inline' }}>Total </h4>(Inclusive of
                    taxes)
                    <span style={{ float: 'right' }}>
                      Rs. {this.state.cart.totalCost}
                    </span>
                    <div>
                      <button
                        onClick={this.getInstaMojoRedirectUrl}
                        className="roomGuestSelectorButtonStyle"
                        id="roomGuestBookNow"
                        style={{ width: '100%' }}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </StickyBox>
              </BrowserView>
            </Col>
            <MobileView>
              <SwipeableBottomSheet overflowHeight={125} open={this.state.swipeableBottomSheetOpen} onChange={this.toggleBottomSheet}>
                <div style={{ height: '425px', backgroundColor: "#fff", padding: "10px" }}>
                  <div>
                    {/* <h4 style={{ textAlign: 'center', fontFamily: 'AllerLight' }}>
                    Reservation Summary
                  </h4> */}
                    <div role="button" onClick={e => this.toggleBottomSheet()} onKeyDown={this.toggleBottomSheet} tabIndex={0}>
                      <h4 style={{ display: 'inline' }}>Total </h4>(Inclusive of
                      taxes)
                      <span style={{ float: 'right' }}>
                        Rs. {this.state.cart.totalCost}
                      </span>
                    </div>
                    <hr />
                    <DateRangePicker
                      horizontalMargin="12"
                      orientation="horizontal"
                      startDateId="startDate"
                      startDatePlaceholderText="Check In "
                      endDatePlaceholderText="Check Out "
                      endDateId="endDate"
                      startDate={this.state.filterDateStart}
                      endDate={this.state.filterDateEnd}
                      onDatesChange={({ startDate, endDate }) => {
                        this.dateFilterUpdated(startDate, endDate);
                      }}
                      focusedInput={this.state.filterDateFocusedInput}
                      onFocusChange={focusedInput => {
                        document.activeElement.blur();
                        this.setState({ filterDateFocusedInput: focusedInput });
                      }}
                    />

                    <hr />
                    <h4>Rooms</h4>
                    {Object.entries(this.state.cart.rooms).map((k, i) => {
                      return (
                        <React.Fragment key={i}>
                          {k[1] > 0 && (
                            <React.Fragment>
                              <div>
                                <p
                                  style={{
                                    textTransform: 'capitalize',
                                    width: '140px',
                                    display: 'inline-block',
                                  }}
                                >
                                  {k[0]}
                                </p>
                                <span style={{}}> X {k[1]}</span>

                                <span style={{ float: 'right' }}>
                                  <Button
                                    onClick={() => this.removeFromCart(k[0])}
                                    style={{
                                      margin: 0,
                                      background: 'none',
                                      border: 'none',
                                      padding: 0,
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      // onClick={()=>this.addToCart(room)}
                                      style={{
                                        fontSize: '16px',
                                        color: '#EF8921',
                                        margin: '1px',
                                      }}
                                    />
                                  </Button>
                                </span>
                                <span style={{ float: 'right' }}>
                                  Rs. {this.state.rate[k[0]]}
                                </span>
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      );
                    })}
                    <hr />
                    <h4>Addons</h4>
                    <CustomInput
                      className="custom-switch"
                      type="checkbox"
                      id="breakfastToggleSwitch"
                      name="customSwitch"
                      onChange={e => this.toggleBreakfast()}
                      checked={this.state.cart.addons.breakfast ? true : false}
                      label="Breakfast"
                      style={{ display: 'inline' }}
                    >
                      <span style={{ float: 'right' }}>
                        Rs.
                        {this.state.cart.addons.breakfast
                          ? this.state.rate.breakfast * this.state.cart.totalRooms
                          : 0}
                      </span>
                    </CustomInput>
                    <CustomInput
                      type="switch"
                      id="airportPickupToggleSwitch"
                      name="pickupToggleSwitch"
                      onChange={e => this.toggleAirportPickup()}
                      checked={this.state.cart.addons.pickup ? true : false}
                      label="Airport Pickup"
                      style={{ display: 'inline' }}
                    >
                      <span style={{ float: 'right' }}>
                        Rs.{' '}
                        {this.state.cart.addons.pickup
                          ? this.state.rate.pickup
                          : 0}
                      </span>
                    </CustomInput>
                    <CustomInput
                      type="switch"
                      id="airportDropToggleSwitch"
                      name="dropToggleSwitch"
                      onChange={e => this.toggleAirportDrop()}
                      checked={this.state.cart.addons.drop ? true : false}
                      label="Airport Drop"
                      style={{ display: 'inline' }}
                    >
                      <span style={{ float: 'right' }}>
                        Rs.{' '}
                        {this.state.cart.addons.drop ? this.state.rate.drop : 0}
                      </span>
                    </CustomInput>
                    <hr />
                    {/* <h4 style={{ display: 'inline' }}>Total </h4>(Inclusive of
                  taxes)
                  <span style={{ float: 'right' }}>
                    Rs. {this.state.cart.totalCost}
                  </span> */}
                    <div>
                      <button
                        onClick={this.getInstaMojoRedirectUrl}
                        className="roomGuestSelectorButtonStyle"
                        style={{ width: '100%' }}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              </SwipeableBottomSheet>
            </MobileView>
          </Row>
        )}
      />
    );
  }
}
export default PackageDesigner;
